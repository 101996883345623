export default function titleCase(str: string) {
  const res = str.toLowerCase()
  const resarr = res.split('_')
  for (let i = 0; i < resarr.length; i++) {
    resarr[i] = resarr[i].charAt(0).toUpperCase() + resarr[i].slice(1)
  }
  return resarr.join(' ')
}

export function titleCaseSpace(str: string) {
  const res = str.toLowerCase()
  const resarr = res.split(' ')
  for (let i = 0; i < resarr.length; i++) {
    resarr[i] = resarr[i].charAt(0).toUpperCase() + resarr[i].slice(1)
  }
  return resarr.join(' ')
}

export function titleCaseDash(str: string) {
  const res = str.toLowerCase()
  const resarr = res.split('-')
  for (let i = 0; i < resarr.length; i++) {
    resarr[i] = resarr[i].charAt(0).toUpperCase() + resarr[i].slice(1)
  }
  return resarr.join(' ')
}

export function camelCaseFromSnake(str: string) {
  const res = str.toLowerCase()
  const resarr = res.split('_')
  for (let i = 1; i < resarr.length; i++) {
    resarr[i] = resarr[i].charAt(0).toUpperCase() + resarr[i].slice(1)
  }
  return resarr.join('')
}
